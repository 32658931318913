<template>
  <KeiAvbAnswerTable dict-type="avb" />
</template>

<script>
import KeiAvbAnswerTable from '@/components/dict/kei/KeiAvbAnswerTable'

export default {
  components: { KeiAvbAnswerTable },
}
</script>
