<template>
  <v-container fluid>
    <v-row class="mt-2">
      <v-col>
        <ReferenceTip>
          Для просмотра архивных обоснований {{ dictTitle }} выберите сначала
          критерий, затем признак его соответствие, при необходимости уточнив
          запрос ТЭГами, и нажмите кнопку "Поиск ответов"
        </ReferenceTip>
      </v-col>
      <v-col />
    </v-row>
    <v-row class="mt-2">
      <v-col>
        <v-select
          label="Критерий"
          v-model="selected"
          :items="criteriaList"
          item-value="index"
          item-text="text"
        >
        </v-select>
      </v-col>
      <v-col />
    </v-row>

    <v-row>
      <v-col>
        <v-radio-group
          hide-details
          class="mt-0 mb-4 pa-0"
          row
          dense
          v-model="correspond"
        >
          <v-radio color="green" :value="true" :label="EX_CORRESPOND" />
          <v-radio color="red" :value="false" :label="EX_NOT_CORRESPOND" />
        </v-radio-group>
      </v-col>
      <v-col />
    </v-row>

    <v-row v-if="selected !== null">
      <v-col class="pa-0">
        <CriterionTags
          :value="selectedData"
          :tags="getTags"
          clearable
          @change="doUpdate"
          shaped
        />
      </v-col>
      <v-col />
    </v-row>

    <v-row>
      <v-col class="text-right">
        <v-btn text :disabled="!canShow" @click="referenceToggle">
          <v-icon left>mdi-magnify</v-icon>
          Поиск ответов
        </v-btn>
      </v-col>
      <v-col />
    </v-row>

    <OverlayDictCriteria
      :show="showDlg"
      :correspond="correspond"
      :tabIndex="selected"
      :dict="getDT"
      hide-templates
      hide-select-btn
      autoclose
      @close="onCloseTemplate"
      :year.sync="answerYear"
      @update:year="updateAnswerYear"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OverlayDictCriteria from '@/components/overlay/OverlayDictCriteria'
import ReferenceTip from '@/components/ReferenceTip'
import ls from '@/lib/localStorageUtils'
import { EX_CORRESPOND, EX_NOT_CORRESPOND } from '../../expAvbKei/const'
import CriterionTags from '@/components/expAvbKei/tags/CriterionTags.vue'

export default {
  name: 'KeiAnswerTable',
  props: {
    dictType: {
      type: String,
      default: '',
    },
  },
  components: {
    OverlayDictCriteria,
    ReferenceTip,
    CriterionTags,
  },
  data: () => ({
    correspond: true, // по умолчанию - соответствует
    selected: null,
    selectedData: [],
    showDlg: false,

    answerYear: ls.load('answerYear'),
  }),
  watch: {
    selected() {
      this.selectedData = []
    },
  },
  methods: {
    ...mapActions(['LOAD_KEI_CRITERIA', 'LOAD_AVB_CRITERIA', 'LOAD_ANSWERS']),
    doUpdate(data) {
      this.selectedData = data || []
    },

    referenceToggle() {
      const is_matched = this.correspond

      let { id: dict_criterion_id } = this.getCriterion || {}

      const tags = []
      for (const item of this.selectedData) {
        const arr = [item?.values].flat().filter(v => v)
        arr.forEach(value => tags.push({ tag: item.name, value }))
      }

      const preparedData = {
        dict_criterion_id,
        is_matched,
        year: this.answerYear || null,
        tags,
      }

      this.LOAD_ANSWERS(preparedData)
      this.showDlg = true
    },
    onCloseTemplate() {
      this.showDlg = false
    },
    updateAnswerYear() {
      if (this.canShow) {
        this.referenceToggle()
        ls.save('answerYear', this.answerYear)
      }
    },
  },
  async mounted() {
    this.isKei ? await this.LOAD_KEI_CRITERIA() : await this.LOAD_AVB_CRITERIA()
  },
  computed: {
    ...mapGetters(['storedKeiCriteria', 'storedAvbCriteria']),
    EX_CORRESPOND: () => EX_CORRESPOND,
    EX_NOT_CORRESPOND: () => EX_NOT_CORRESPOND,
    getDT() {
      return this.dictType.toUpperCase()
    },
    isKei() {
      return this.getDT === 'KEI'
    },
    stored() {
      return this.isKei ? 'storedKeiCriteria' : 'storedAvbCriteria'
    },
    dictTitle() {
      return this.isKei ? 'КЭИ' : 'АВБ'
    },
    getCriterion() {
      return this[this.stored][this.selected]
    },
    getTags() {
      return this.getCriterion?.tags
    },
    canShow() {
      return this.selected !== null && this.correspond !== null
    },
    criteriaList() {
      return this[this.stored]?.reduce((it, item, index) => {
        it.push({ index, ...item, text: `${item.order}. ${item.name}` })
        return it
      }, [])
    },
  },
}
</script>
